<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-0"
      >
        <div class="w-100 h-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="sideImg"
            alt="Forgot Password V3"
            class="w-100 h-100"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-0 text-center"
          >
            <b-img
              fluid
              :src="logoImg"
            />
          </b-card-title>
          <b-card-text class="mb-1">
            <div class="d-flex flex-column text-center title-container">
              <span>Welcome to CMS</span>
              <a class="text-info">www.akarahospitality.com</a>
            </div>
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="forgot-password-email"
                      v-model="emailForm"
                      :state="errors.length > 0 ? false:null"
                      name="forgot-password-email"
                      placeholder="Email"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                pill
                :disabled="isDisabledButton"
              >
                Reset Password
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-1 text-secondary">
            <span>If you have any issue. Please contact 097-006-0592</span>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BRow, BCol, BLink, BCardTitle, BCardText, BImg, BInputGroupPrepend, BInputGroup, BForm, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAuth from './useAuth'
import authStoreModule from './authStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      emailForm: '',
      sideImg: require('@/assets/images/pages/login-v3.svg'),
      logoImg: require('@/assets/images/logo/logo.svg'),
      required,
      email,
    }
  },
  computed: {
    isDisabledButton() {
      return !this.emailForm
    },
  },
  methods: {
    async validationForm() {
      const isValid = await this.$refs.simpleRules.validate();
      if (!isValid) return;

      const { data } = await this.fetchInfo(this.emailForm);
      if (!data.id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email is invalid.',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        return;
      }

      const { status } = await this.forgotPassword({ id: data.id, email: this.emailForm });
      if (status !== 200) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error sending email.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        return;
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Reset password link has been sent to your email.',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
  },
  setup() {
    const AUTH_APP_STORE_MODULE_NAME = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP_STORE_MODULE_NAME)) store.registerModule(AUTH_APP_STORE_MODULE_NAME, authStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP_STORE_MODULE_NAME)) store.unregisterModule(AUTH_APP_STORE_MODULE_NAME)
    })

    const {
      toast,
      fetchInfo,
      forgotPassword,
    } = useAuth()
    
    return {
      toast,
      fetchInfo,
      forgotPassword,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.text-info {
  color: #0E76A8 !important;
}
</style>
